import {SendDownloadLinkResponse} from "../../pages/api/download-link";
import {nextApi} from "../../utils/fetch/fetches";

export const sendDownloadLink = async ({
  phone,
  practiceSlug,
  csrfToken,
  captcha,
}: SendDownloadLinkRequest): Promise<SendDownloadLinkResponse> => {
  const response = await nextApi()("/api/download-link", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "CSRF-Token": csrfToken || "null",
    },
    credentials: "same-origin",
    body: JSON.stringify({
      phone,
      practiceSlug,
      captcha,
    }),
  });
  return response.json();
};

export type SendDownloadLinkRequest = {
  phone: string | null;
  practiceSlug: string;
  csrfToken?: string;
  captcha: string | null;
};
