import NextImage, {ImageProps} from "next/image";
import React, {memo} from "react";

type newImageProps = Omit<ImageProps, "alt"> & {alt?: string};

const Image: React.FC<newImageProps> = ({alt = "", ...rest}) => {
  return <NextImage alt={alt} {...rest} />;
};

export default memo(Image);
